<template>
  <section class="order-detail">
    <div v-if="loading_order" class="order-detail__loading">
      <Loader />
    </div>
    <div v-else-if="!Object.keys(order_data).length" class="order-detail__not-found">
      {{ order_page_translations.order_not_found }}
    </div>
    <div v-else>
      <h2 class="title marketplace-title">{{ order_page_translations.title }}:</h2>

      <hr>

      <div class="order-detail__basic-info">
        <div>
          <span>{{ order_page_translations.order_number }}:</span>
          <span>{{ order_page_translations.created_at }}:</span>
          <span class="order-detail__basic-info-id">{{ order_page_translations.order_id }}:</span>
        </div>
        <div>
          <span>#{{ order_data.order_number }}</span>
          <span>{{ date }}</span>
          <span class="order-detail__basic-info-id">{{ order_data.id }}</span>
        </div>
      </div>

      <hr>

      <div class="order-detail__address-wrapper">
        <div class="order-detail__address">
          <p>{{ order_page_translations.shipping_address }}:</p>
          <div
            v-for="[key, val] in addresses.shipping"
            :key="`billing-key-${key}`"
            class="order-detail__address-field"
          >
            <b>{{ get_translation(key) || $translate(`order.${key}`) }}:</b> {{ val }}
          </div>
        </div>
        <div class="order-detail__address">
          <p>{{ order_page_translations.billing_address }}:</p>
          <div
            v-for="[key, val] in addresses.billing"
            :key="`billing-key-${key}`"
            class="order-detail__address-field"
          >
            <b>{{ get_translation(key) || $translate(`order.${key}`) }}:</b> {{ val }}
          </div>
        </div>
      </div>

      <hr>

      <div class="order-detail__products">
        <h3>{{ order_page_translations.products_title }}:</h3>

        <div
          v-for="(product, index) in order_data.line_items"
          :key="`order-product-${product.id}-${index}`"
          class="order-detail__product"
        >
          <img :src="product.image || '/static/backgrounds/default-img.jpg'" alt="Product image"
          ><div class="order-detail__product-info">
            <router-link
              :to="create_correct_product_url(product, is_marketplace_environment)"
              class="link"
              :aria-label="`Checkout item ${product.title}`"
              itemprop="category/subcateogry/item"
            >
              {{ product.title }}
            </router-link>
            <span>x {{ product.quantity }}</span>
          </div
          ><div class="order-detail__product-price">{{ order_data.presentment_currency }} {{ product.prices ? product.prices[order_data.customer_locale].price : product.price }}</div>
        </div>
      </div>
    
      <hr>

      <div class="order-detail__financials">
        <h3>{{ order_page_translations.price_title }}:</h3>
        <div>
          <p><b>{{ order_page_translations.products_title }}:</b><span>{{ order_data.presentment_currency }} {{ order_data.total_line_items_price.toFixed(2) }}</span><br/></p>
          <p v-if="order_data.total_tax"><b>{{ order_page_translations.taxes }}:</b><span>{{ order_data.presentment_currency }} {{ order_data.total_tax.toFixed(2) }}</span><br/></p>
          <p><b>{{ order_page_translations.subtotal }}:</b><span>{{ order_data.presentment_currency }} {{ order_data.subtotal_price.toFixed(2) }}</span><br/></p>
          <p><b>{{ order_page_translations.shipping_price_title }}:</b><span>{{ shipping_cost ? `${order_data.presentment_currency} ${shipping_cost.toFixed(2)}` : (translations.free || $translate("order.free")) }}</span><br/></p>
          <p><b>{{ order_page_translations.total }}:</b><span><b>{{ order_data.presentment_currency }} {{ order_data.total_price.toFixed(2) }}</b></span></p>
        </div>
      </div>
    
      <hr>

      <div
        class="order-detail__tracking"
        :class="{ 'order-detail__tracking--has_value': (order_data.fulfillments || []).length }"
      >
        <h3>{{ order_page_translations.tracking_title }}:</h3>
        <div id="YQContainer" />
        <p v-if="!(order_data.fulfillments || []).length">{{ order_page_translations.tracking_no_value_message }}</p>
      </div>
    
      <hr>

      <button
        class="button button--wide order-detail__download"
        :class="{ 'order-detail__download--marketplace': is_marketplace_environment }"
        @click="download_invoice"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M4 22v-20h16v11.543c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-7.362zm18-7.614v-14.386h-20v24h10.189c3.163 0 9.811-7.223 9.811-9.614zm-5-1.386h-10v-1h10v1zm0-4h-10v1h10v-1zm0-3h-10v1h10v-1z"/></svg>
        {{ order_page_translations.download_invoice_for_order }}
      </button>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex"
import moment from "moment"
import Loader from "@/applications/Shared/components/Loader/index.vue"
import dateformat from "dateformat"
import { fetch_order, fetch_order_store_data } from "../methods/ajax/endpoints"
import { date_time_format_dateformat, default_language, translated_languages } from "../constants/other"
import { CLIENT_STORE } from "../../Client/constants/other"
import create_correct_product_url from "../../Client/methods/create_correct_product_url"
import download_order from "../methods/download_order"
import translator from "../translator"

export default {
  components: {
    Loader
  },
  data() {
    return {
      loading_order: true,
      order_data: {},
    }
  },
  computed: {
    ...mapState(CLIENT_STORE, [
      "translations",
      "selected_language",
      "shipping_options",
      "rest_config"
    ]),
    ...mapState([
      "is_marketplace_environment",
    ]),
    order_page_translations() {
      return this.is_marketplace_environment ? {
        order_not_found: this.$translate("order.order_not_found"),
        title: this.$translate("order.title"),
        order_number: this.$translate("order.order_number"),
        created_at: this.$translate("order.created_at"),
        order_id: this.$translate("order.order_id"),
        shipping_address: this.$translate("order.shipping_address"),
        billing_address: this.$translate("order.billing_address"),
        products_title: this.$translate("order.products_title"),
        price_title: this.$translate("order.price_title"),
        taxes: this.$translate("order.taxes"),
        tracking_title: this.$translate("order.tracking_title"),
        tracking_no_value_message: this.$translate("order.tracking_no_value_message"),
        shipping_price_title: this.$translate("order.shipping_price_title"),
        total: this.$translate("order.total"),
        subtotal: this.$translate("order.subtotal"),
        download_invoice_for_order: this.$translate("order.download_invoice_for_order")
      } : this.translations.order_page
    },
    addresses() {
      return this.order_data.shipping_address ? {
        shipping: Object.entries(JSON.parse(JSON.stringify({
          ...this.order_data.shipping_address,
          address_first: this.order_data.shipping_address.address1,
          address_second: this.order_data.shipping_address.address2,
          address1: undefined,
          address2: undefined,
          latitude: undefined,
          longitude: undefined,
          country_code: undefined,
          billing_data: undefined,
          name: undefined
        }))).filter(([_, val]) => val),
        billing: Object.entries(JSON.parse(JSON.stringify({
          ...this.order_data.billing_address,
          address_first: this.order_data.billing_address.address1,
          address_second: this.order_data.billing_address.address2,
          address1: undefined,
          address2: undefined,
          latitude: undefined,
          longitude: undefined,
          country_code: undefined,
          name: undefined
        }))).filter(([_, val]) => val)
      } : {}
    },
    date() {
      return dateformat(this.order_data.created_at, date_time_format_dateformat)
    },
    shipping_cost() {
      return this.order_data.shipping_lines ? this.order_data.shipping_lines.reduce(
        (total, { price }) => Number(price) + total, 0
      ) : 0
    }
  },
  async created() {
    if (this.$route.params.order_id || this.$route.params.subtype) {
      const { data } = await fetch_order(
        this.$route.params.order_id || this.$route.params.subtype,
        this.$route.params.store_id || this.$route.params.subsubtype
      )

      this.order_data = data
      this.loading_order = false

      if (this.order_data.fulfillments) this.track_order(this.order_data.fulfillments[0].tracking_number)
    }
  },
  methods: {
    create_correct_product_url,
    async download_invoice() {
      let shipping_options, rest_config
      const i18n = await translator(
        true,
        translated_languages.includes(this.selected_language) ? this.selected_language : default_language,
      )
      const translate = async (keys, params) => await i18n.t(keys, params)

      if (!this.rest_config) {
        const { data } = await fetch_order_store_data(this.$route.params.store_id || this.$route.params.subsubtype)

        shipping_options = data.shipping_options
        rest_config = data.shipping_options
      }
      
      download_order(
        this.order_data,
        translate,
        moment,
        shipping_options || this.shipping_options,
        rest_config || this.rest_config
      )
    },
    async track_order(num) {
      await this.$loadScript("https://www.17track.net/externalcall.js")
      
      YQV5.trackSingle({
        YQ_ContainerId: "YQContainer",
        YQ_Height: 500,
        YQ_Fc: "0",
        YQ_Lang: this.selected_language,
        YQ_Num: num
      });
    },
    get_translation(key) {
      if (key === "businessID") return this.translations["business_id"]
      if (key === "vatID") return this.translations["VAT_id"]
      return this.translations[key]
    }
  }
}
</script>

<style lang="scss">
  @use "../../../styles/_global-constants" as *;
  @use "../../../styles/_marketplace-constants" as *;

  .order-detail {
    position: relative;
    min-height: 400px;
    color: $pure_black;

    &__download {
      display: block;
      margin: 40px auto;
      font-size: 16px;

      &--marketplace {
        background-color: $brand-color--dark;
        color: $pure-white;
        border: none;

        &:hover {
          color: $brand-color--dark;
          background-color: $pure-white;
          border: none;

          svg {
            fill: $brand-color--dark;
          }
        }

        svg {
          fill: $pure-white;
        }
      }

      svg {
        height: 16px;
        margin-right: 5px;
      }
    }

    @media (max-width: $maximum-width) {
      padding: 0 $spacing--small;
    }

    h2 {
      padding-top: 0;
      margin: $spacing--medium 0 $spacing--large;
    }

    h3 {
      padding: 0;
      margin: $spacing--small 0;
    }

    hr {
      margin: 0;
    }

    &__address {
      display: inline-block;
      width: 50%;
      text-align: left;
      box-sizing: border-box;
      word-break: break-all;
      vertical-align: top;

      @media (max-width: $tablet--small) {
        display: block;
        width: 100% !important;
        font-size: 13px;
      
        &:first-child {
          margin-bottom: 20px;
        }
      }

      &-wrapper {
        margin: 20px 0;
      }

      &-field {
        line-height: 1.3em;
      }

      p {
        font-weight: bold;
        margin: 0 0 20px;
      }
      
      &:first-child {
        width: calc(50% - 25px);
        margin-right: 20px;
      }
    }

    &__basic-info {
      padding: 10px 0;

      @media (max-width: $tablet--small) {
        font-size: 13px;
      }

      div:first-child span {
        font-weight: bold;
        margin-bottom: 5px;
      }

      div {
        $order_info_value_width: 130px;

        &:first-child {
          @media (max-width: $tablet--small) {
            width: $order_info_value_width;
          }
        }

        @media (max-width: $tablet--small) {
          display: inline-block;
          width: calc(100% - #{$order_info_value_width} - 10px);
          box-sizing: border-box;
          vertical-align: top;
        }
      }

      span {
        display: inline-block;
        width: 25%;
        padding: 0 5px;
        box-sizing: border-box;
        vertical-align: top;

        @media (max-width: $tablet--small) {
          width: 100% !important;
          margin-bottom: 10px !important;
        }
      }

      &-id {
        width: calc(50% - 10px) !important;
      }
    }

    &__not-found {
      position: absolute;
      text-align: center;
      margin: 0;
      font-size: 20px;
      width: 100%;
      top: calc(50% - 10px);
    }

    &__product {
      border: 1px solid $pure_black;
      margin-bottom: 10px;
      border-radius: $border-radius;
      padding: $spacing--tiny;

      &:last-child {
        margin-bottom: 0;
      }

      * {
        display: inline-block;
        vertical-align: middle;
        box-sizing: border-box;
        line-height: 50px;

        @media (max-width: $mobile) {
          display: block;
          width: 100%;
          font-size: 13px;
          line-height: 30px;
        }
      }

      img {
        width: 50px;
        height: 50px;
        object-fit: contain;

        @media (max-width: $mobile) {
          max-width: 100%;
          height: auto;
          margin: 0 auto $spacing--tiny;
        }
      }

      &-info {
        $qunatity_width: 40px;

        position: relative;
        width: calc(100% - 50px - 110px);
        margin: 0;
        padding: 0 $qunatity_width 0 10px;

        @media (max-width: $mobile) {
          width: 100%;
          border: 0;
          margin: 0;
          border-top: 1px solid $pure_black;
          border-bottom: 1px solid $pure_black;
        }

        span {
          display: flex;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          width: $qunatity_width;
          font-weight: bold;
          text-align: center;
          justify-content: center;
          align-items: center;
        }
      }

      &-price {
        width: 110px;
        text-align: right;
        padding: 0 5px;
        border-left: 0;

        @media (max-width: $mobile) {
          width: 100%;
          padding: 0 10px;
        }
      }

      &s {
        margin: 20px 0;
      }
    }

    &__loading {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      z-index: 5;
      background-color: rgba(var(--body_color_RGB), 0.6);

      .spinner {
        position: absolute;
        left: calc(50% - 20px);
        top: calc(50% - 20px);
        z-index: 1000;

        &-inner {
          &.loading {
            transform: scale(1.2) !important;
          }
        }
      }
    }

    &__financials {
      margin: 20px 0;

      div {
        text-align: right;
      }
      & > b {
        display: inline-block;
        margin-right: 20px;
        width: 200px;
      }
      p {
        margin: 0 0 0 auto;
        line-height: 25px;
      }
      span {
        display: inline-block;
        width: 150px;
      }
    }

    &__tracking {
      position: relative;
      margin-bottom: $spacing--large;

      $iframe_header_heigth: 56px; // heigth of iframe logo header
      $iframe_border_color: #e0e0e0;

      &--has_value {
        padding-top: 20px;

        h3 {
          position: absolute;
          top: 19px;
          left: -1px;
          right: -3px;
          margin: 0;
          height: $iframe_header_heigth;
          background-color: $pure_white;
          border-bottom: 1px solid $iframe_border_color;
        }

        &:after {
          content: "";
          position: absolute;
          bottom: 42px;
          left: 1px;
          right: 1px;
          height: 100px;
          background-color: $pure_white;

          @media (max-width: 768px) {
            height: 70px;
          }
          @media (max-width: 468px) {
            height: 60px;
          }
        }
      }
    }
  }
</style>
